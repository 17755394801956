import React from 'react'
import './Status.scss'

const Status = (props) => {
  let className = 'status'
  if (props.code === 1) {
    className += ' status--error'
  }
  return (
    <div className={className}>{props.code === 1 && <span>Error: </span>}{props.children}</div>
  )
}

export default Status
