import React, { Component } from 'react'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'
import AuthUserContext from './AuthUserContext'

const withAuthentication = (Comp) => {
  class WithAuthentication extends Component {
    constructor (props) {
      super(props)
      this.state = {
        authUser: JSON.parse(window.localStorage.getItem('authUser'))
      }
    }
    componentDidMount () {
      this.authListener = firebase.auth().onAuthStateChanged((authUser) => {
        if (authUser) {
          this.setState({ authUser })
          window.localStorage.setItem('authUser', JSON.stringify(authUser))
        } else {
          window.localStorage.removeItem('authUser')
          this.setState({ authUser: null })
        }
      })
    }
    componentWillUnmount () {
      this.authListener()
    }
    render () {
      return (
        <AuthUserContext.Provider value={this.state}>
          <Comp {...this.props} />
        </AuthUserContext.Provider>
      )
    }
  }
  return WithAuthentication
}

export default withAuthentication
