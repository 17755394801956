import React from 'react'
import firebase from 'firebase/app'
import 'firebase/storage'
import withAuthorization from '../withAuthorization'
import { Container, Row, Col } from '../commons/Grid'
import Section from '../commons/Section'
import Title from '../commons/Title'
import Control from '../commons/Control'
import Status from '../commons/Status'
import { CSSTransition } from 'react-transition-group'

class Dashboard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      file: null,
      showStatus: false,
      statusCode: '',
      statusMsg: '',
      updated: ''
    }
    this.handleOnDrop = this.handleOnDrop.bind(this)
    this.storageRef = firebase.storage().ref('inventario')
  }
  componentDidMount () {
    this.storageRef.child('inventario_lomasacueducto.pdf').getMetadata()
      .then((metadata) => {
        const date = new Date(metadata.updated)
        const dateDay = date.getDate()
        const dateMonth = date.getMonth()
        const dateYear = date.getFullYear()
        const dateHours = date.getHours()
        const dateMinutes = date.getMinutes()
        const months = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ]
        const updated = dateDay + ' de ' + months[dateMonth] + ' de ' + dateYear + ' a las ' + dateHours + ':' + dateMinutes + ' hrs.'
        this.setState({ updated })
      })
      .catch((err) => {
        console.error(err)
      })
  }
  handleOnDrop (accepted, rejected) {
    if (!rejected.length > 0) {
      const file = accepted[0]
      this.setState({ file })
      this.uploadFile(file)
    }
  }
  uploadFile (file) {
    const fileRef = this.storageRef.child('inventario_lomasacueducto.pdf')
    fileRef.put(file)
      .then((snap) => {
        this.setState({
          showStatus: true,
          statusCode: 0,
          statusMsg: 'El archivo se ha cargado con éxito.'
        })
      })
      .catch((err) => {
        const errorCode = err.code
        const errorMessage = err.message
        this.setState({
          showStatus: true,
          statusCode: 1,
          statusMsg: errorMessage
        })
        console.error(errorCode)
      })
  }
  render () {
    return (
      <main>
        <Container>
          <Row>
            <Col
              lg='8'
              lgOffset='2'
              xl='6'
              xlOffset='3'
            >
              <Section>
                <div className='centered'>
                  <Title>Administrar <span>Inventario</span></Title>
                  <p>Da <b>click o arrastra</b> un archivo para modificar el inventario disponible.<br />El archivo se guardará automáticamente y pasará a reemplazar al inventario actual.</p>
                  <Control
                    type='file'
                    name='file'
                    label='Archivo'
                    accept='application/pdf'
                    value={this.state.file}
                    onChange={this.handleOnDrop}
                  />
                  <CSSTransition
                    in={this.state.showStatus}
                    classNames={'status'}
                    timeout={300}
                    mountOnEnter
                    unmountOnExit
                    onEntered={() => setTimeout(() => this.setState({ showStatus: false }), 5000)}
                    onExited={() => this.setState({ statusMsg: '', statusCode: '' })}
                  >
                    <Status code={this.state.statusCode}>{this.state.statusMsg}</Status>
                  </CSSTransition>
                  {this.state.updated && <p className='small'>Última actualización: {this.state.updated}</p>}
                </div>
              </Section>
            </Col>
          </Row>
        </Container>
      </main>
    )
  }
}

export default withAuthorization(Dashboard)
