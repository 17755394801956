import React from 'react'
import './Section.scss'

const Section = ({ children }) => (
  <section className='section'>
    {children}
  </section>
)

export default Section
