import React, { Component } from 'react'
import AuthUserContext from './AuthUserContext'
import { Redirect } from 'react-router-dom'

const withAuthorization = (Comp, isAuthRoute) => {
  class WithAuthorization extends Component {
    render () {
      return (
        <AuthUserContext.Consumer>
          {({ authUser }) => {
            if (authUser) {
              if (isAuthRoute) {
                return <Redirect to='/' />
              }
              return <Comp {...this.props} />
            } else {
              if (!isAuthRoute) {
                return <Redirect to='/login' />
              }
              return <Comp {...this.props} />
            }
          }}
        </AuthUserContext.Consumer>
      )
    }
  }
  return WithAuthorization
}

export default withAuthorization
