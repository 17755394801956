import React from 'react'
import './Subtitle.scss'

const Subtitle = (props) => (
  <h2 className='subtitle'>
    {props.children}
  </h2>
)

export default Subtitle
