import React from 'react'
import { Container, Row, Col } from '../commons/Grid'
import Section from '../commons/Section'
import Title from '../commons/Title'
import { Link } from 'react-router-dom'

const Error404 = () => (
  <main>
    <Container>
      <Row>
        <Col
          md='6'
          mdOffset='3'
          lg='4'
          lgOffset='4'
        >
          <Section>
            <div className='centered'>
              <Title>Error <span>404</span></Title>
              <p>La página que estás buscando no se encuentra en este servidor.</p>
              <p className='small'><Link to='/' className='link'>Regresar al inicio</Link></p>
            </div>
          </Section>
        </Col>
      </Row>
    </Container>
  </main>
)

export default Error404
