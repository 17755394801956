import React from 'react'
import './Header.scss'
import imagotipo from '../../images/imagotipo.png'
import Logout from '../modules/Logout'
import AuthUserContext from '../AuthUserContext'

const Header = () => (
  <div className='header'>
    <div className='header__logo'>
      <img src={imagotipo} alt='Lomas Acueducto' />
    </div>
    <AuthUserContext.Consumer>
      {({ authUser }) => (
        authUser &&
          <div className='header__button'>
            <Logout />
          </div>
      )}
    </AuthUserContext.Consumer>
  </div>
)

export default Header
