import React from 'react'
import Dropzone from 'react-dropzone'
import { ReactComponent as Icon } from '../../images/upload.svg'
import './Control.scss'

const Control = (props) => {
  let component
  if (props.type === 'text' || props.type === 'password') {
    component = <Input {...props} />
  } else if (props.type === 'submit' || props.type === 'button') {
    component = <Button {...props} />
  } else if (props.type === 'file') {
    component = <File {...props} />
  }
  let className = 'control'
  if (props.error) {
    className += ' control--has-error'
  }
  return (
    <div className={className}>
      {component}
    </div>
  )
}

const Input = (props) => (
  <div className={props.value ? 'input input--has-value' : 'input'}>
    <input
      type={props.type}
      name={props.name}
      id={props.name}
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
    />
    <label htmlFor={props.name}>{props.error || props.label}</label>
    <div className='input__detail' />
  </div>
)

const Button = (props) => (
  <div className='button'>
    <input
      type={props.type}
      name={props.name}
      value={props.value}
      onClick={props.onClick}
      disabled={props.disabled}
    />
  </div>
)

const File = (props) => (
  <Dropzone
    name={props.name}
    accept={props.accept}
    disabled={props.disabled}
    multiple={false}
    onDrop={(acceptedFiles, rejectedFiles) => props.onChange(acceptedFiles, rejectedFiles)}
  >
    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
      let className = 'file'
      if (isDragActive) className += ' file--active'
      if (isDragReject) className += ' file--reject'
      return (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {props.value ? <div className={className}>
            <div className='file__content'>
              <div className='file__text'>{props.value.name}</div>
            </div>
          </div> : <div className={className}>
            <div className='file__content'>
              <div className='file__icon'><Icon /></div>
              <div className='file__text'>Click o arrastra en esta área</div>
            </div>
          </div>}
        </div>
      )
    }}
  </Dropzone>
)

export default Control
