import React from 'react'
import './Logout.scss'
import { ReactComponent as Icon } from '../../images/logout.svg'
import firebase from 'firebase/app'
import 'firebase/auth'

const Logout = (props) => {
  const logout = (ev) => {
    ev.preventDefault()
    firebase.auth().signOut()
      .catch((err) => console.error(err))
  }
  return (
    <button className='logout' type='button' onClick={logout}>
      <Icon />
    </button>
  )
}

export default Logout
