import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import withAuthorization from '../withAuthorization'
import { Container, Row, Col } from '../commons/Grid'
import Subtitle from '../commons/Subtitle'
import Control from '../commons/Control'
import Status from '../commons/Status'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

class Login extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      user: '',
      userError: null,
      password: '',
      passwordError: null,
      showStatus: false,
      statusCode: '',
      statusMsg: ''
    }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }
  handleOnChange (ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
      [ev.target.name + 'Error']: null
    })
  }
  onSubmit (ev) {
    ev.preventDefault()
    if (this.state.user === '') {
      this.setState({
        userError: 'Necesitas ingresar tu email'
      })
      return false
    }
    if (this.state.password === '') {
      this.setState({
        passwordError: 'Hace falta tu contraseña'
      })
      return false
    }
    firebase.auth().signInWithEmailAndPassword(this.state.user, this.state.password)
      .catch((error) => {
        var errorCode = error.code
        var errorMessage = error.message
        if (errorCode === 'auth/invalid-email') {
          this.setState({
            userError: 'Verifica tu usuario',
            showStatus: true,
            statusCode: 1,
            statusMsg: 'El usuario tiene un formato de email incorrecto.'
          })
        } else if (errorCode === 'auth/user-not-found') {
          this.setState({
            userError: 'Verifica tu usuario',
            showStatus: true,
            statusCode: 1,
            statusMsg: 'Este usuario no existe en la plataforma.'
          })
        } else if (errorCode === 'auth/wrong-password') {
          this.setState({
            passwordError: 'Verifica tu contraseña',
            showStatus: true,
            statusCode: 1,
            statusMsg: 'Contraseña incorrecta.'
          })
        } else {
          this.setState({
            showStatus: true,
            statusCode: 1,
            statusMsg: errorMessage
          })
        }
      })
  }
  render () {
    return (
      <main>
        <Container>
          <Row>
            <Col
              md='6'
              mdOffset='3'
              lg='4'
              lgOffset='4'
            >
              <div className='centered'>
                <Subtitle>Administración Inventario</Subtitle>
                <p>Inicia sesión para continuar.</p>
                <form onSubmit={this.onSubmit}>
                  <Control
                    type='text'
                    name='user'
                    value={this.state.user}
                    onChange={this.handleOnChange}
                    label='Email'
                    error={this.state.userError}
                  />
                  <Control
                    type='password'
                    name='password'
                    value={this.state.password}
                    onChange={this.handleOnChange}
                    label='Contraseña'
                    error={this.state.passwordError}
                  />
                  <p className='small'><Link to='/forgot' className='link'>¿Olvidaste tu contraseña?</Link></p>
                  <CSSTransition
                    in={this.state.showStatus}
                    classNames={'status'}
                    timeout={300}
                    mountOnEnter
                    unmountOnExit
                    onEntered={() => setTimeout(() => this.setState({ showStatus: false }), 5000)}
                    onExited={() => this.setState({ statusMsg: '', statusCode: '' })}
                  >
                    <Status code={this.state.statusCode}>{this.state.statusMsg}</Status>
                  </CSSTransition>
                  <Control
                    type='submit'
                    name='submit'
                    value='Entrar'
                  />
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    )
  }
}

export default withAuthorization(Login, true)
