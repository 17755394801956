import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import withAuthorization from '../withAuthorization'
import { Container, Row, Col } from '../commons/Grid'
import Subtitle from '../commons/Subtitle'
import Control from '../commons/Control'
import Status from '../commons/Status'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'

class ForgotPassword extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      user: '',
      userError: null,
      showStatus: false,
      statusCode: '',
      statusMsg: ''
    }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }
  handleOnChange (ev) {
    this.setState({
      [ev.target.name]: ev.target.value,
      [ev.target.name + 'Error']: null
    })
  }
  onSubmit (ev) {
    ev.preventDefault()
    if (this.state.user === '') {
      this.setState({
        userError: 'Necesitas ingresar tu email'
      })
      return false
    }
    firebase.auth().sendPasswordResetEmail(this.state.user)
      .then(() => {
        this.setState({
          showStatus: true,
          statusCode: 0,
          statusMsg: 'Un correo de recuperación ha sido enviado a tu email. Revisa tu bandeja de entrada y sigue las instrucciones.'
        })
      })
      .catch((error) => {
        var errorCode = error.code
        var errorMessage = error.message
        if (errorCode === 'auth/invalid-email') {
          this.setState({
            showStatus: true,
            statusCode: 1,
            statusMsg: 'El usuario tiene un formato de email incorrecto.'
          })
        } else if (errorCode === 'auth/user-not-found') {
          this.setState({
            showStatus: true,
            statusCode: 1,
            statusMsg: 'Este usuario no existe en la plataforma.'
          })
        } else {
          this.setState({
            showStatus: true,
            statusCode: 1,
            statusMsg: errorMessage
          })
        }
      })
  }
  render () {
    return (
      <main>
        <Container>
          <Row>
            <Col
              md='6'
              mdOffset='3'
              lg='4'
              lgOffset='4'
            >
              <div className='centered'>
                <Subtitle>Recuperar <span>contraseña</span></Subtitle>
                <p>Ingresa el correo electrónico que utilizas para acceder al administrador.</p>
                <form onSubmit={this.onSubmit}>
                  <Control
                    type='text'
                    name='user'
                    value={this.state.user}
                    onChange={this.handleOnChange}
                    label='Email'
                    error={this.state.userError}
                  />
                  <p className='small'><Link to='/login' className='link'>Regresar a iniciar sesión</Link></p>
                  <CSSTransition
                    in={this.state.showStatus}
                    classNames={'status'}
                    timeout={300}
                    mountOnEnter
                    unmountOnExit
                    onEntered={() => setTimeout(() => this.setState({ showStatus: false }), 5000)}
                    onExited={() => this.setState({ statusMsg: '', statusCode: '' })}
                  >
                    <Status code={this.state.statusCode}>{this.state.statusMsg}</Status>
                  </CSSTransition>
                  <Control
                    type='submit'
                    name='submit'
                    value='Recuperar'
                  />
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    )
  }
}

export default withAuthorization(ForgotPassword, true)
