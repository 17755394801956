import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import withAuthentication from './withAuthentication'
import Dashboard from './routes/Dashboard'
import Login from './routes/Login'
import ForgotPassword from './routes/ForgotPassword'
import Error404 from './routes/Error404'
import Header from './layout/Header'

const App = () => (
  <Router>
    <div className='app'>
      <Header />
      <Switch>
        <Route path='/' exact component={Dashboard} />
        <Route path='/login' component={Login} />
        <Route path='/forgot' component={ForgotPassword} />
        <Route component={Error404} />
      </Switch>
    </div>
  </Router>
)

export default withAuthentication(App)
