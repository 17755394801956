import React from 'react'
import './Grid.scss'

const Container = (props) => {
  return (
    <div className='container'>
      {props.children}
    </div>
  )
}
const Row = (props) => {
  let className = 'row'
  if (props.noGutters) {
    className += (' row--no_gutters')
  }
  return (
    <div className={className}>
      {props.children}
    </div>
  )
}

const Col = (props) => {
  let className = 'col'
  if (props.sm) {
    className += (' col-sm-' + props.sm)
  }
  if (props.md) {
    className += (' col-md-' + props.md)
  }
  if (props.lg) {
    className += (' col-lg-' + props.lg)
  }
  if (props.xl) {
    className += (' col-xl-' + props.xl)
  }
  if (props.offset) {
    className += (' col-offset-' + props.smOffset)
  }
  if (props.smOffset) {
    className += (' col-sm-offset-' + props.smOffset)
  }
  if (props.mdOffset) {
    className += (' col-md-offset-' + props.mdOffset)
  }
  if (props.lgOffset) {
    className += (' col-lg-offset-' + props.lgOffset)
  }
  if (props.xlOffset) {
    className += (' col-xl-offset-' + props.xlOffset)
  }
  return (
    <div className={className}>
      {props.children}
    </div>
  )
}

export { Container, Row, Col }
